@font-face {
    font-family: "social-share-icon"; /* Project id 3277636 */
    src: url('https://at.alicdn.com/t/font_3277636_750jlq4rpzu.woff2?t=1648202327063') format('woff2'),
    url('https://at.alicdn.com/t/font_3277636_750jlq4rpzu.woff?t=1648202327063') format('woff'),
    url('https://at.alicdn.com/t/font_3277636_750jlq4rpzu.ttf?t=1648202327063') format('truetype');
}

.social-share-icon {
    font-family: "social-share-icon" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.social-share-icon-tieba:before {
    content: "\e639";
}

.social-share-icon-douban:before {
    content: "\e688";
}

.social-share-icon-qq:before {
    content: "\e882";
}

.social-share-icon-twitter:before {
    content: "\e883";
}

.social-share-icon-weibo:before {
    content: "\e884";
}

.social-share-icon-wechat:before {
    content: "\e885";
}

.social-share-icon-facebook:before {
    content: "\e8e7";
}

.social-share-icon-qzone:before {
    content: "\e629";
}
